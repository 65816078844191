import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Context
import { PageTitleContext } from "../components/context/page-title";
import { PageTypeContext } from "../components/context/page-type";

const Page = styled.div`
  padding: 0 90px 60px 90px;
  position: relative;

  @media (max-width: 1200px) {
    padding: 0 60px;
  }

  @media (max-width: 1000px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const ContentContainer = styled.div`
  // max-width: 1260px;
  // margin: 0 auto;
`;

const PrivacyPolicy = ({ data }) => {
  const [pageTitleContext, setPageTitleContext] = useContext(PageTitleContext);
  const [pageTypeContext, setPageTypeContext] = useContext(PageTypeContext);

  useEffect(() => {
    setPageTitleContext("Privacy");
    setPageTypeContext(`privacy`);
  }, []);

  return (
    <Page>
      <ContentContainer>
        <div
          dangerouslySetInnerHTML={{
            __html: data.prismicPrivacyPolicy.data.text.html,
          }}
        />
      </ContentContainer>
    </Page>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  {
    prismicPrivacyPolicy {
      data {
        text {
          html
        }
      }
    }
  }
`;
